// Important notes: okta sign in widged is imported as plugin is nuxt plugin folder
import { RegistrationFormValues, Actor } from 'HealthAdvisorFrontendApp'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { IdToken, AccessToken, OktaSessionData } from 'Okta'
import { LoggedUser, SignupResponse } from 'HealthAdvisorBackendApp'
import { routes } from './routes'

export const checkOktaSession = async (
  oktaPlugin: any
): Promise<OktaSessionData> => {
  if (!oktaPlugin) {
    // no okta plugin found
    return {
      isLogged: false,
      status: 'MISSING_AUTH_PLUGIN',
      oktaUser: undefined
    }
  }

  const session = await oktaPlugin.authClient.session.get()

  if (!session || !session.user) {
    // plugin exist, but user is not logged
    return {
      isLogged: false,
      status: session?.status || '',
      oktaUser: undefined
    }
  }

  const user = await session.user()

  // user is logged
  return {
    isLogged: Boolean(user && user.id),
    status: session.status,
    oktaUser: user
  }
}

// This function is executed when okta.hasTokenInUrl is true,
// to parse url data and fill tokenManager with tokews
// NOT USED, but let's keep it here for references
export const parseDecodatedOktaLoginRedirectUrl = async (oktaPlugin: any) => {
  try {
    const responseWithTokens = await oktaPlugin.authClient.token.parseFromUrl()
    const idToken: IdToken = responseWithTokens?.tokens?.idToken
    const accessToken: AccessToken = responseWithTokens?.tokens?.accessToken

    oktaPlugin.authClient.tokenManager.add('idToken', idToken)
    oktaPlugin.authClient.tokenManager.add('accessToken', accessToken)

    return responseWithTokens
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('hasTokenInUrl parsing error', error)
  }
}

export const loginWithBackend = async (
  oktaPlugin: any,
  $axios: NuxtAxiosInstance
) => {
  // const idToken: IdToken = await oktaPlugin.authClient.tokenManager.get(
  //   'idToken'
  // )
  // const accessToken: AccessToken = await oktaPlugin.authClient.tokenManager.get(
  //   'accessToken'
  // )

  // // first we check if we have a valid idToken and is not expired
  // const oktaSession = await oktaPlugin.authClient.session.get()
  // if (!oktaSession || oktaSession.status !== 'ACTIVE') {
  //   return
  // }

  // const timeNow = new Date().getTime() / 1000

  // const areValidTokens =
  //   idToken &&
  //   idToken.expiresAt &&
  //   idToken.expiresAt > timeNow &&
  //   accessToken &&
  //   accessToken.expiresAt &&
  //   accessToken.expiresAt > timeNow

  // if (!areValidTokens) {
  //   oktaPlugin.authClient.signOut()
  //   return
  // }

  // good to log in
  const backendSigninToken = await $axios.get('/signinToken/')
  const secKey = backendSigninToken.data?.data || ''

  const backendSignInResponse = await $axios.post(
    '/signin/',
    {
      sec_key: secKey,
      token: 'none',
      nonce: 'none',
      aud: 'none',
      // token: accessToken.value,
      // nonce: idToken.claims.nonce,
      // aud: idToken.claims.aud,
      state: localStorage.getItem('oktaState') || ''
    },
    {
      headers: {
        // 'X-Authorization': `Bearer ${idToken.value}`
        'X-Authorization': `Bearer none-1`
      }
    }
  )

  return backendSignInResponse
}

// Prepare actor Data for store
// we mix okta user detail with our backend user datails
// to make Actor data object that can be store into vuex
export const makeActorObject = (
  backendLoggedUserData: LoggedUser,
  idToken: IdToken,
  accessToken: AccessToken,
  oktaUserId: string
) => {
  const {
    ID,
    first_name,
    last_name,
    full_name,
    account_verified,
    account_email,
    sign_up_step
  } = backendLoggedUserData

  const storeActor: Actor = {
    firstName: first_name,
    lastName: last_name,
    fullName: full_name,
    email: account_email,
    providerId: oktaUserId,
    internalId: ID,
    accountVerified: Boolean(account_verified),
    signupStep: sign_up_step,
    tokens: {
      accessToken,
      idToken
    }
  }
  return storeActor
}

// LOGOUT
// logout user from both okta and our api
export const logoutWithBackend = async (
  oktaPlugin: any,
  $axios: NuxtAxiosInstance
) => {
  // const idToken: IdToken = await oktaPlugin.authClient.tokenManager.get(
  //   'idToken'
  // )
  // const accessToken: AccessToken = await oktaPlugin.authClient.tokenManager.get(
  //   'accessToken'
  // )

  const backendSignoutResponse = await $axios.post(
    '/signout/',
    {
      token: 'none',
      aud: 'none'
      // token: accessToken?.value || '',
      // aud: idToken?.claims?.aud || ''
    },
    {
      headers: {
        'X-Authorization': `Bearer none-1`
      }
    }
  )

  // const oktaLogoutReposense = await oktaPlugin.authClient
  //   .revokeAccessToken()
  //   .then(async () => {
  //     const closeSession = await oktaPlugin.authClient.closeSession()
  //     return closeSession
  //   })

  return {
    apiResponse: backendSignoutResponse
    // oktaReponse: oktaLogoutReposense
  }
}

// This function is used to signup user to our system
// our backend will also register user in okta
export const registerWithBackend = async (
  $axios: NuxtAxiosInstance,
  formValues: RegistrationFormValues
) => {
  const backendSignupToken = await $axios.get('/signupToken/')
  const secKey = backendSignupToken.data?.data || ''

  const dataToPost = {
    title: formValues.title,
    firstname: formValues.firstName,
    lastname: formValues.lastName,
    email: formValues.email,
    phone: formValues.telephone,
    password: formValues.password,
    cfpassword: formValues.confirmPassword,
    toc_accept: formValues.termsAccepted ? 1 : 0,
    role: formValues.role,
    sec_key: secKey,
    btn_submit: true
  }

  const backendSignupResponse = await $axios.post<{
    data?: SignupResponse
    error?: boolean
    success?: boolean
  }>('/signup/', dataToPost)

  return backendSignupResponse
}

export const redirectUserAfterLogin = (
  completedSignupStep: string,
  vueRouterInstance: any,
  fallBackToHome?: boolean
) => {
  const $router = vueRouterInstance

  if (completedSignupStep === '1') {
    $router.push(routes.onboardingStep2.makeUrl())
    return
  }

  if (completedSignupStep === '2') {
    $router.push(routes.onboardingStep3BeforeDirectDebit.makeUrl())
    return
  }

  // in case after login we want to take user to homepage
  if (fallBackToHome) {
    $router.push(routes.home.makeUrl())
  }
}
