













































import Vue, { PropOptions } from 'vue'
import { routes } from '@/utils/routes'
import ArrowRight from '~/static/icons/arrow-right.svg?inline'

export type SearchedPlace = {
  name: string
  fullAddress: string
  lat: number
  lng: number
}

export default Vue.extend({
  name: 'HeroSearch',
  components: {
    ArrowRight
  },
  props: {
    forLocation: {
      type: Boolean,
      default: false
    },
    submitOnLocationSelect: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: undefined
    },
    size: {
      type: String,
      default: 'normal'
    } as PropOptions<'small' | 'normal' | 'large'>,
    withShadow: {
      type: Boolean,
      default: false
    },
    initialValue: {
      type: String,
      default: ''
    },
    onSubmit: {
      type: Function,
      default: () => undefined
    } as PropOptions<(payload: SearchedPlace | string) => void>
  },
  data() {
    return {
      inputValue: this.$props.initialValue || '',
      place: undefined as SearchedPlace | undefined,
      textInputData: this.$props.initialValue || '',
      autocompleteOptions: {
        componentRestrictions: {
          country: ['gb']
        }
      }
    }
  },
  computed: {
    getInputSize() {
      return this.$props.size === 'large'
        ? 'form-control-lg'
        : this.$props.size === 'small'
        ? 'form-control-sm'
        : 'form-control'
    },
    isDisabled() {
      // we don't want to have a disable button when is for location
      return this.forLocation
        ? false
        : !(this.$data.textInputData && this.$data.textInputData.length)
    }
  },
  methods: {
    handleSubmit(e: Event): void {
      e.preventDefault()
      if (this.forLocation && this.$data.place) {
        this.onSubmit(this.$data.place)
      } else {
        this.onSubmit(this.$data.textInputData)
      }
    },
    setPlace(searchedPlace: unknown) {
      const place = searchedPlace as any
      const name = place && place.name
      const fullAddress = place && place.formatted_address
      const lat = place && place.geometry && place.geometry.location.lat()
      const lng = place && place.geometry && place.geometry.location.lng()
      this.$data.place =
        lat && lng
          ? {
              name,
              fullAddress,
              lat,
              lng
            }
          : undefined

      this.$data.inputValue = name

      if (this.$props.submitOnLocationSelect) {
        this.onSubmit(this.$data.place)

        this.$router.push({
          path: routes.search.makeUrl(),
          query: {
            location: name,
            mid_lat: lat,
            mid_lng: lng
          }
        })
      }
    },
    onBtnSubmitClick() {
      if (this.forLocation && typeof Event === 'function') {
        // old IE can't enter here, we just don't care... progressive enhancement :)
        // simulating enter key press event on input, to select first place
        const ev = new Event('keydown', {
          bubbles: true,
          cancelable: true
        }) as any
        ev.which = 13
        ev.keyCode = 13

        const refInput = this.$refs.inputLocation as any
        refInput.$refs.input.dispatchEvent(ev)
      }
    },
    setInputFocus() {
      const refName = this.$props.forLocation ? 'inputLocation' : 'inputText'
      const refInput = this.$refs[refName] as any
      if (refInput && refInput.$el) {
        refInput.$el.focus()
      }
    }
  }
})
