














import Vue from 'vue'
export default Vue.extend({
  functional: true,
  props: {
    to: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    isSpecial: {
      type: Boolean,
      default: false
    },
    extraCssClass: {
      type: String,
      required: false,
      default: ''
    }
  }
})
